:root {
  --main-color: #144d3e;
  --main-color-highlight: #1c6350;
  --item-color: #aee038;
  --items-bg: #e7ecdab0;
  --company-red : #ce5b68 ;
  --company-yellow: #f4b767;
}

body {
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
}

.App {
  text-align: center;
  background-color: var(--company-red);
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--main-color-highlight);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Top page navigation */

#nav {
  transition: all 350ms 100ms ease-in-out;
  padding: 0;
}

#cont {
  width: 150% !important;
}

.nav-color {
  background-color: var(--main-color);
}

.nav-items {
  margin: auto;
  font-size: 1.3vw;
  width: max-content;
}

.nav-brand {
  font-size: 3vh;
  width: max-content;
  margin-right: 10%;
  color: white;
  
  
}
.nav-logo {
  width: 8vw;
  margin-left: -4vw;
  transition: all 0.2s ease-in-out;
}


.navbar-image {
  width: 50px;
  margin: auto;
  margin-right: 2.5vh;
  margin-left: 2.5vh;
  box-shadow: rgba(0,0,0, 0.5) 1px 1px 1px 1px;
  transition: all 0.2s ease-in-out;
}

.navbar-image:hover, .nav-logo:hover {
  transform: scale(1.2);
}

.navbar-toggler {
  border: none !important;
}

/* Footer */

section {
  min-height: 10vh ;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 2vh;
  padding-bottom: 4vh;
}

.footer {
  background-color: var(--main-color) !important;
  color: white;
  padding-top: 3vh;
}

.footer-lastSection {
  background-color: rgba(0,0,0, 0.1);
}

.footer-icons {
  display: inline-block ;
  width: fit-content;
  padding-bottom: 3vh;
}

.footer-icon {
  margin-bottom: 3vh;
  margin-right: 4vw;
  margin-left: 4vw;
  background: radial-gradient(circle at center, white 50%, #ffffff00 , var(--main-color) 100% ) ; 
}

.footer-title {
  font-size: 5vh;
  text-align: center;
}

.footer-text {
  font-size: 18px;
  list-style: none;
  text-align: center;
}

.map {
  min-height: 35vh;
  min-width: 35vw;
 
}

.footer-zert-container {
  display: flexbox;
  height: 25vh;
}

.footer-zert {
  height: 100%;
  margin-left: 3vw;
  flex: 50%;
}

.footer-zert:hover {
  cursor: pointer;
  box-shadow: 0 0 1rem black ;
}

.footer-zert-num {
  padding: 0.5vh;
  text-align: center;
  padding-left: 3vw;
}

/* Recently carousel */

.carousel-image {
  min-height: 43vw;
  max-height: 43vw;
  animation: 0.5s ease-in 1 scale;
  transition: all 2000ms ease-in-out;
}

.carousel-image:hover {
  transform: scale(1.2);
}

.carousel-caption {
  color: white !important;
  background: radial-gradient(ellipse at center, rgba(0,0,0,1) 0%, transparent 75%) ;
  animation: opacity 1250ms ease-in ;
  margin-left: 20vw;
  margin-right: 20vw;
}


/* Animations */

@keyframes scale {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
  
}

@keyframes image-scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
  
}


@keyframes slideInFromLeft {
  0% {
      transform: translateX(-100%);
  }
  100% {
      transform: translateX(0);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
  
}

/* Products */

#produkte {
  animation: 500ms ease-in-out 1 slideInFromLeft;
  background-color: var(--company-red);
}
  

.items-header {
  padding-top: 3vh;
  margin-bottom: 5vh;
  font-size: 3vw;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  
}

.items-item {
  opacity: 1;
  margin-bottom: 6vh !important;
  margin-left: 1vw;
  margin-right: 1vw;
  z-index: 1;
  transition: all 0.4s ease-in-out !important;
  min-height: 400px;
  max-height: 400px;
  width: calc(100%- 32px);
  background-color: var(--main-color) !important;
  color: #ffffff88;
  border-radius: 1rem !important;
  border: none !important;
  overflow: hidden;
  box-shadow: 0 0 1em #000000;
}

.items-item:hover {
  background-color: var(--main-color-highlight) !important;
  color: white;
}

.items-info-icon {
  margin-bottom: 1vh;
  margin-left: 5vh;
  
}

.items-info-icon:hover {
  cursor: pointer;
  color: var(--company-yellow);
  background: radial-gradient(circle at center , white 50%, #ffffff00, var(--company-red) 100%);
  transform: scale(1.1) !important; 
}

.items-item-image {
  height: 400px;
} 

img {
  object-fit: cover;
}

.items-item-container {
  position: relative;
}

.items-item-description {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #144d3eb2;
  top: 250px;
}

.items-item-text {
  height: 10vh;
  padding-bottom: 0.25vh;
  overflow: hidden;
}

.badge {
  margin-left: 1vh !important;
}

/* About section */

.about-container {
  background-color: var(--company-yellow);
  width: 100%;
  padding-right: 8vw;
  padding-left: 8vw;
  margin-right: auto;
  margin-left: auto;
  text-align: left;
  color: #000000;
  
}

.about-text-container {
  width: 50%;
  padding-top: 3vh;
  padding-bottom: 3vh;
  display: inline-block;
}

.about-header {
  margin: 0;
  font-size: 3vw;
  font-weight: bold;
}

.about-text {
  font-size: 1.4vw;
  padding: 3vh;
  padding-left: 0vh;
}

/* Tabs in about section */

button.nav-link {
  color :rgba(0,0,0, 0.5) !important
}

button.nav-link.active {
  color: #000000 !important;
}

/* Tab Entry */

.tab-entry-container {
  display: flex;
  overflow: hidden;
  width: 100%;
  padding: 1vh;
  text-align: left;
}

.tab-entry-tab {
  border: 1px solid rgba(0,0,0, 0.1);
  border-radius: 5px;
  margin-left: 1vh !important; 
  margin-right: 1vh !important;
  
}

.tab-entry-image {
 float: left;
 padding-top: 1vh;
 padding-bottom: 1vh;
 
}

.tab-entry-description {
  font-size: 2vmax;
  padding-bottom: 2vh;
  margin-right: 1vh;
}

.tab-entry-description-inner {
  width: 100%;
  height: 100%;
  padding: 1vh;
  color: #000000b2;
  text-align: center;
}

.tab-entry-title {
  text-align: center;
}

.price-view-button {
  position: absolute;
  bottom: 3vh;
  left: 40%
}

#popover-basic {
  background: var(--company-yellow);
}

#popover-body {
  font-weight: bold;
  padding: 2px;
}

.table {
  border-color: transparent !important;
}

/* Picture grid */

 .picture-grid-container {
  width: 50%;
  padding-top: 14vh;
  padding-left: 3vw;
  padding-bottom: 3vh;
  display: inline-flex;
  flex-wrap: wrap;
  vertical-align: top;
}

.picture-grid-image {
  z-index: 1;
  vertical-align: middle;
  width: 100%;
  transition: all 200ms ease-in;
  margin-bottom: 3vh;
  
  /* border-radius: 1.5rem; */
  box-shadow: 0 0 1em black;
} 

.picture-grid-image:hover {
  transform: scale(1.2);
}

.picture-grid-column {
  flex: 25%;
  margin-right: 3vh;
}

/* Impressum */

.impressum-button , .datenschutz-button {
  border: none;
  background: transparent;
  margin-top: 2vh;
  color: white;
  font-size: 2vh;
  transition: all 300ms ease-in-out;
}

.impressum-button:hover, .impressum-button-active , .datenschutz-button-active , .datenschutz-button:hover {
  transform: scale(1.1);
  border-radius: 25rem;
  padding-left: 1vh;
  padding-right: 1vh;
  background-color: var(--main-color-highlight);
}

.impressum-text {
  padding-top: 2vh;
}

/* Media query for bigger screens */

@media screen and (min-width: 770px) {
    /* Growing transition is copied from: https://css-tricks.com/snippets/css/scale-on-hover-with-webkit-transition/ */

  .grow { 
    transition: all .2s ease-in-out; 
  }

  .grow:hover { 
    transform: scale(1.1); 
    z-index: 10;
  }
}

/* Medium- small */

@media only screen and (max-width: 992px) and (min-width: 767px ) {

  .footer-zert {
    margin: 0;
    padding-left: 1vw;
    width: 49%
  }
}

/* Medium */

@media only screen and (max-width: 1200px) {

  .nav-items, .nav-brand {
    font-size: 3vh !important;
  }

  .nav-brand {
    display: none;
  }

  .nav-logo {
    margin-right: 10vh;
    margin-left: -3vh;
  }

  .items-item{
    min-height: 300px;
    max-height: 300px;
  }

  .items-item-description{
    top: 200px !important;
  }

}

/* Tablets in landscape */
@media only screen and (max-width: 1400px) and (min-height: 769px) {

  .about-text-container {
    width: 100% !important;
  }

  .picture-grid-container {
    padding-top: 10vw !important;
    display: none !important;
  }

  .navbar-image {
    width: 5vh;
    margin: 1vh;
  }

  .nav-logo {
    height: 8vh;
    margin-right: 0;
  }

  .nav-items {
    font-size: 1.8vw;
  }

  .about-header, .items-header {
    font-size: 3vh !important;
  }

  .about-text {
    font-size: 2vh !important;
  }

  .items-item{
    min-height: 400px;
    max-height: 400px !important;
  }

  .items-item-description {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #144d3eb2;
    top: 250px !important;
  }

  .footer-text {
    font-size: 2vh;
  }

  .footer-lastSection {
    font-size: 2vh;
  }

  .footer-zert {
    
    margin: 0;
    padding-left: 1vw;
    
    height: 20vh;
  }

  .footer-zert-num {
    font-size: 2vh;
  }

  .carousel-image {
    min-height: 55vh;
    max-height: 55vh;
  }

}

/* Media query for small screens */

@media only screen and (max-width: 767px) {

  .image-fit-mehl {
    width: 100%;
    height: 100% !important;
    object-position: 100% 50%;
  }

  .nav-brand {
    display: none;
  }

  .navbar-brand {
    margin-right: -5vw !important;
    width: 23%;
  }

  .nav-logo {
    margin-left: 0;
    height: 7vh;
    width: max-content;
  }

  .navbar-image {
    width: 6vh;
    margin: auto;
    margin-right: 1vh;
    margin-left: 1vh;
    transition: all 0.2s ease-in-out;
  }

  .footer-title {
    font-size: 6vw;
    text-align: center;
  }
  
  .footer-text {
    font-size: 4vw;
    text-align: center;
    list-style: none;
  }

  .footer-icons {
    display: inline-block ;
    width: fit-content;
  }
  
  .footer-icon {
    margin-bottom: 3vh;
    margin-right: 2vw;
    margin-left: 2vw;
    background: radial-gradient(circle at center, white 50%, #ffffff00 , var(--main-color) 100% ) ; 
  }

  .items-header {
    margin-bottom: 0vh;
    margin-top: 1vh;
    font-size: 6vw;
    color: rgba(255, 255, 255, 0.5);
  }

  .items-info-icon {
    display: inline;
    border-radius: 0.25rem;
    margin: auto;
    margin-bottom: 1vh;
    margin-top: .5vh;
    margin-left: 1vh;
    width: 5vh;
    height: 5vh;
    background: var(--company-red) ;
    transition: all 0.5s ease-in-out !important;
  }

  .items-info-icon:hover {
    cursor: pointer;
    color: var(--company-yellow);
    background: radial-gradient(circle at center , white 50%, #ffffff00, var(--company-red) 100%);
    transform: scale(1.1) !important; 
  }

  .items-item {
    margin: 2vh;
    margin-left: 2vh;
    margin-right: 2vh;
    min-height: 350px ;
    color: white;
  }

  .items-item-text {
    font-size: 3.5vw;
  }
  
  .items-item-image {
    height: 100vh;
  }

  .grow { 
    transition: none; 
  }

  .grow:hover { 
    transform: none; 
    z-index: 10;
  }

  .carousel-image {
    min-height: calc(55vh + 10vw);
    max-height: calc(55vh + 10vw);
    animation: none;
  }

  .carousel-caption {
    color: white !important;
    background: radial-gradient(ellipse at center, rgba(0,0,0,1) 0%, transparent 75%) ;
    animation: opacity 1250ms ease-in ;
    margin-left: 0vw;
    margin-right: 0vw;
  }

  .map {
    max-height: 35vh;
    min-width: 75vw;
    margin: auto !important;
  }

  .about-container {
    background-color: var(--company-yellow);
    width: 100%;
    padding-right: 8vw;
    padding-left: 8vw;
    margin-right: auto;
    margin-left: auto;
    text-align: left;
    color: #000000;
  }

  .about-header {
    font-size: 6vw !important;
  }

  .about-text-container {
    width: 100% !important;
    
  }

  .about-text {
    font-size: 4vw !important;
    padding-top: 1vh !important;
  }

  .picture-grid-container {
    display: none !important;
    width: 100% !important;
    padding-top: 0 !important;
  }
  .picture-grid-image {
    height: 30vw !important;
  }
}

/* Big desktop screens */

@media only screen and (min-width: 1400px)  {
  .nav-logo {
      margin-left: -2vw;
  }

  .nav-brand {
    margin-left: 0vw;
    padding-right: 0vw;
  }
}

/* Large desktop screens */

@media only screen and (min-width: 1600px)  {
  .nav-logo {
      margin-left: -13vw;
  }

  .nav-brand {
    margin-left: -5vw;
    padding-right: 5vw;
  }
}




